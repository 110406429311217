$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});


$(document).on('click', '#modalFormToggle, .modalFormToggle', function (e) {
    e.preventDefault();
    var modal = $('#modalForm');
    var el = $(this);

    $(modal).data('href', $(el).attr('href'));
    $(modal).data('noModalSubmit', $(el).hasClass('no-modal-submit'));
    $(modal).data('formName', '');

    reloadModal(modal);

    return false; // prevent other actions
});

function reloadModal(modal) {

    // сначала показать форму
    modal.modal('show');

    // потом очистить содержимое
    modal.find('.modal-body')
        .html('')
        .append($('<div/>').attr('id','ajax-loader')
            .append($('<img/>').attr('src','/images/ajax_loading.gif')));

    modal.find('.modal-locked').html('');
    modal.find('.modal-title').html('');

    // потом загрузить содержимое по ajax
    $.get($(modal).data('href'), function (data) {
        var content;

        // если ответ сервера JSON - разобрать его
        if (jQuery.type(data) === 'object') {

            content = data.content;

            // обработать переданные параметры формы
            // название формы
            $(modal).data('formName', data.formName);
            if(data.modalTitle !== 'undefined') {
                modal.find('.modal-title').html(data.modalTitle);
            }
            if (data.blockData) {
                modal.find('.modal-locked').html(data.blockTitle);
                $(modal).data('noModalSubmit', true);
                $(modal).data('noDeleteButton', true);
            }

        } else {
            content = data;
        }

        modal.find('.modal-body').html(content);
        onModalLoad(modal);
    });
}

function onModalLoad(modal) {
    $(modal).find('#modalDeleteButton').hide();
    $(modal).find('#modalPostButton').hide();

    if ($(modal).data('noModalSubmit') || $(modal).find('form').hasClass('no-modal-submit')) {
        $(modal).find('#modalSaveButton').hide();
    } else {
        $(modal).find('#modalSaveButton').show().removeAttr('disabled');
    }

    if ($(modal).find('form').hasClass('show-post-button')) {
        $(modal).find('#modalPostButton').show().removeAttr('disabled');
    }
    if (!$(modal).data('noDeleteButton') && $(modal).find('form').hasClass('show-delete-button')) {
        $(modal).find('#modalDeleteButton').show().removeAttr('disabled');
    }
}

$(document).on('click', '#modalSaveButton', function () {
    submitModalForm();
});


$(document).on('click', '#modalPostButton', function () {
    $('input[name=posted]').val(true);
    submitModalForm();
});

$(document).on('click', '#modalDeleteButton', function () {
    if (confirm('Are you sure?')) {
        $('input[name=deletion_mark]').val(true);
        submitModalForm();
    }
});

$(document).on('keyup keypress','#modalForm form', function(e) {

    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
        e.preventDefault();
        return false;
    }
});

function submitModalForm() {

    beforeModalFormSubmit();

    var form = $('.modal-body').find('form');
    var modal = $('#modalForm');

    if ($(form).hasClass('optionFormSubmitNoAjaxClass')) {
        $(form).submit();
        return true;
    }

    $.ajax({
        type: "POST",
        url: form.attr('action'),
        data: form.serialize(),
        complete: function (data) {
            processModalResponse(data);
        }
    }).fail(function (data) {

        if (data.status == 422) {

            var errors = data.responseJSON;
            //всем полям  статус ок
            $(modal).find('div.form-group').removeClass('has-error has-feedback');
            $(modal).find('div.form-group').addClass('has-success has-feedback');
            /*$('span.glyphicon-remove').remove();
             $('<span class="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span>').appendTo($(modal).find('div.form-group'));
             */
            // удалить все окна об ошибках

            $('div.form-control-message').remove();

            $.each(errors, function (key, value) {
                $(modal).find('#' + key).parent('div.form-group').removeClass('has-success has-feedback');
                $(modal).find('#' + key).parent('div.form-group').addClass('has-error has-feedback');

                $(modal).find('#' + key).parent().parent('div.form-group').removeClass('has-success has-feedback');
                $(modal).find('#' + key).parent().parent('div.form-group').addClass('has-error has-feedback');

                /*$(modal).find('#' + key).siblings('span.glyphicon-ok').remove();

                 $(modal).find('#' + key).parent().siblings('span.glyphicon-ok').remove();

                 $('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>').appendTo($(modal).find('#' + key).parent('div.form-group'));
                 $('<div class="alert alert-danger form-control-message" role="alert"></div>').appendTo($(modal).find('#' + key).parent('div.form-group'));

                 $('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>').appendTo($(modal).find('#' + key).parent().parent('div.form-group'));
                 $('<div class="alert alert-danger form-control-message" role="alert"></div>').appendTo($(modal).find('#' + key).parent().parent('div.form-group')).append(value.toString());
                 */
            });

            afterModalFormSubmit();
        }
        else {
            console.log(data.errors);
        }
    });

}

function beforeModalFormSubmit() {
    var modal = $('#modalForm');
    $(modal).find('#modalSaveButton').attr('disabled', 'disabled');
    $(modal).find('#modalPostButton').attr('disabled', 'disabled');
    $(modal).find('#modalDeleteButton').attr('disabled', 'disabled');
}

function afterModalFormSubmit() {
    var modal = $('#modalForm');
    $(modal).find('#modalSaveButton').show().removeAttr('disabled');
    $(modal).find('#modalPostButton').removeAttr('disabled');
    $(modal).find('#modalDeleteButton').removeAttr('disabled');
}

function processModalResponse(data) {

    var isJson = true;
    if (data.responseText) {
        data = data.responseText;
    }
    if (jQuery.type(data) === 'string') {
        try {
            data = jQuery.parseJSON(data);
        }
        catch (err) {
            console.log(err);
            isJson = false;
        }
    }

    if (isJson) {

        if (data.status == 'fail') {
            return false;
        } else if (data.status == 'ERROR') {
            console.log(data.message);
            return false;
        }

        var modal = $('#modalForm');

        if (data.action == 'process_invoker_action' && data.owner_id) {

            var invoker = $('#modalFormToggle[data-owner-id="' + data.owner_id + '"]');
            $(invoker)
                .addClass('ajaxAction')
                .removeAttr('id')
                .attr('href', $(invoker).data('postAction'))
                .click();

        }
        if (data.action == 'reload_page') {

            window.location.reload();

        } else if (data.action == 'update_comment') {

            // FIXME make universal action
            updateComment(data.purch_id, data.comment);

        } else if (data.action == 'callback') {

            // выполнить пользовательскую функцию, расположенную в форме модального окна
            window["callbackModal"]();
        } else if (data.action == 'callback_static') {

            // выполнить пользовательскую функцию, расположенную в форме
            window[data.actionCallback](data.actionCallbackParams);
        }

        if (data.action == 'reload_modal') {

            if (data.reload_action) {
                $(modal).data('href', data.reload_action);
            }
            reloadModal($(modal));

        } else if (data.status == 'OK') {
            if ((modal.data('bs.modal') || {}).isShown) {
                $(modal).modal('hide');
            }
        }

        return (data.status == 'ok');
    }


}